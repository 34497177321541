.all{
    width: 100%;
    height: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
    margin-bottom: 20px;
    text-align: center;
    color: black;
}
.LogoRegisterDiv{
    display: flex;
    align-items: center;
    justify-content: center;

}
.logo{
width: 50%;
   
}
.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    font-size: small;
    width: 20em;

}
.buttonType{
    margin-bottom: 20px;
    
}