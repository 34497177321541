@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,900&display=swap');
.palette.primary.main{
    color: gainsboro;
}
.iconGain{
    float: right;
    margin-top: 9px;
    background-color: rgb(255, 255, 255);
    margin-right: 5px;
    border-radius: 90%;
    -webkit-border-radius: 90%;
    -moz-border-radius: 90%;
    -ms-border-radius: 90%;
    -o-border-radius: 90%;
}

.gainPaper{
    background-color: black;
    width: 100%;
    height: 100%;
    
}
.chiffres{
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    margin-left: 10px;
}

.paraGain{
    font-size: 10px;
    margin-left: 10px;
}

@media screen and (min-width: 900px)  {
    .BigImg{
    display: flex;
    }
    .SmallImg{
        display: none;
    }

}

@media screen and (max-width: 900px)  {
    .SmallImg{
    display: flex;
    }
    .BigImg{
        display: none;
    }

}