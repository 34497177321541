.box{
    background-color:'#0a1929';
    margin: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.Form{
    width: 70%;
    text-align: center;
    color: white;
    text-shadow: 0 0 10px rgba(5, 3, 87, 0.5), 0 0 1px rgba(24, 21, 202, 0.5);
}
.button{
    color: white;
    background-color: rgba(250, 235, 215, 0.349);
}
.Response{
    background-color: rgb(35, 209, 44);
    border-radius: 0.5em;
    color: black;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
    font-size: 0.6em;
margin: 20px;
}