@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,900&display=swap);
body {
  overflow-y: scroll;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  
}
html, body, #app, #app>div {
  height: 100%;
  width: 100%; 
  color: white;
}


body{
    margin: 0;
    padding: 0;
}
.containerLogin{
    display: flex;
  flex-direction: column;
  align-items: center;
}
.containerLogo {
    width: 150px;
    height: 150px;
    margin-top: 20px;
   

}
.logoM{
    
    height: 150px;
    width: 150px;
  
}
.forms{
    margin: 50px;
}
.registerLink{
   
    margin-bottom: 10px;
}
.register{
    color: rgb(158, 26, 26);
    text-align: center;

}
.all{
    width: 100%;
    height: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
    margin-bottom: 20px;
    text-align: center;
    color: black;
}
.LogoRegisterDiv{
    display: flex;
    align-items: center;
    justify-content: center;

}
.logo{
width: 50%;
   
}
.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    font-size: small;
    width: 20em;

}
.buttonType{
    margin-bottom: 20px;
    
}
html, body, #app, #app>div {
    height: 100%
  }
.UserDashOne{
    margin-top: 0px;
    margin-top: none;
    padding-top: 0px;


}
.allUserDash{
    
    height: 50px;
    font-size: small;
    background:radial-gradient(circle, rgba(251,200,63,0.6012780112044818) 0%, rgba(198,152,71,1) 48%);
    color: aliceblue;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
}


.MuiTypography-noWrap{
    width: 90%;
}
.icon{
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: flex-end;
    float: left;
    margin-right: 0px;
}

.BOXUSER {
    background: url(/static/media/fondapp-1000px.f12ccc67.svg);
    background-repeat:repeat;
    background-size: 1000px;
    
    
}
.palette.primary.main{
    color: gainsboro;
}
.iconGain{
    float: right;
    margin-top: 9px;
    background-color: rgb(255, 255, 255);
    margin-right: 5px;
    border-radius: 90%;
    -webkit-border-radius: 90%;
    -moz-border-radius: 90%;
    -ms-border-radius: 90%;
    -o-border-radius: 90%;
}

.gainPaper{
    background-color: black;
    width: 100%;
    height: 100%;
    
}
.chiffres{
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    margin-left: 10px;
}

.paraGain{
    font-size: 10px;
    margin-left: 10px;
}

@media screen and (min-width: 900px)  {
    .BigImg{
    display: flex;
    }
    .SmallImg{
        display: none;
    }

}

@media screen and (max-width: 900px)  {
    .SmallImg{
    display: flex;
    }
    .BigImg{
        display: none;
    }

}
.box{
    background-color:'#0a1929';
    margin: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.Form{
    width: 70%;
    text-align: center;
    color: white;
    text-shadow: 0 0 10px rgba(5, 3, 87, 0.5), 0 0 1px rgba(24, 21, 202, 0.5);
}
.button{
    color: white;
    background-color: rgba(250, 235, 215, 0.349);
}
.Response{
    background-color: rgb(35, 209, 44);
    border-radius: 0.5em;
    color: black;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
    font-size: 0.6em;
margin: 20px;
}
.title{

text-align: center;
padding-top: 10px;
}

.MuiDataGrid-root{
    background-color: white;
}
.h3{
    text-align: center;
    color: white;
    text-shadow: 0 0 2px rgba(255,255,255,.5), 0 0 1px rgba(255,255,255,.5);
    padding-top: 10px;
}
body{
    overflow-y: scroll;
}
.div{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: cornflowerblue;
    float: left

}

.ici {
 
    font-size: 0.5em;
    margin: 0.5em;
}
.charts{
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.edit{
  border: 2px solid grey;
  justify-content: center;
  text-align: center;
  display: flex;
  margin: 20px;
  border-radius: 5%;
  -webkit-border-radius: 5%;
  -moz-border-radius: 5%;
  -ms-border-radius: 5%;
  -o-border-radius: 5%;
  background: rgb(218, 219, 224);
}
