.title{

text-align: center;
padding-top: 10px;
}

.MuiDataGrid-root{
    background-color: white;
}
.h3{
    text-align: center;
    color: white;
    text-shadow: 0 0 2px rgba(255,255,255,.5), 0 0 1px rgba(255,255,255,.5);
    padding-top: 10px;
}
body{
    overflow-y: scroll;
}