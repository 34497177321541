.div{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: cornflowerblue;
    float: left

}

.ici {
 
    font-size: 0.5em;
    margin: 0.5em;
}