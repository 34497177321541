.MuiTypography-noWrap{
    width: 90%;
}
.icon{
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: flex-end;
    float: left;
    margin-right: 0px;
}

.BOXUSER {
    background: url('../../img/fondapp-1000px.svg');
    background-repeat:repeat;
    background-size: 1000px;
    
    
}