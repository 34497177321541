@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400&display=swap');
html, body, #app, #app>div {
    height: 100%
  }
.UserDashOne{
    margin-top: 0px;
    margin-top: none;
    padding-top: 0px;


}
.allUserDash{
    
    height: 50px;
    font-size: small;
    background:radial-gradient(circle, rgba(251,200,63,0.6012780112044818) 0%, rgba(198,152,71,1) 48%);
    color: aliceblue;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
}

