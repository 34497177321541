body{
    margin: 0;
    padding: 0;
}
.containerLogin{
    display: flex;
  flex-direction: column;
  align-items: center;
}
.containerLogo {
    width: 150px;
    height: 150px;
    margin-top: 20px;
   

}
.logoM{
    
    height: 150px;
    width: 150px;
  
}
.forms{
    margin: 50px;
}
.registerLink{
   
    margin-bottom: 10px;
}
.register{
    color: rgb(158, 26, 26);
    text-align: center;

}